import {Message} from 'element-ui';
import router from "./router";
export default {
    install (Vue) {
        Vue.prototype.BaseUrl='https://api.iim-token.me'
        Vue.prototype.$back = function(code,msg,$msgtype=false) {
            let type= code ==200 ?'success' : 'error'
            type=$msgtype ? $msgtype :type
                Message({
                    message:msg,
                    type:type
                })
        };
        Vue.prototype.$redirect=function (time,route) {
             setTimeout(() =>{
                 router.push(route)
                 // window.console.log(VueRouter.prototype.push({path:'/home'}),url)
                // 某些定时器操作
            }, time);
        }

        Vue.prototype.$new_pages=function (pages,querys={}) {
            if(querys){
                this.$router.push({
                    path: pages,
                    query: querys,
                  });
            }else{
                this.$router.push({
                    path: pages,
                    
                  });
            }
           
       }

    }
}
