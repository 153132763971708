import Vue from 'vue'
import App from './App.vue'
import router from "@/web/router.js";
import Global from '@/web/global.js'
import Http from '@/web/http.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import Qs from 'qs'


// import '../public/ue/ueditor.config.js'
// import '../public/ue/ueditor.all.min.js'
// import '../public/ue/lang/zh-cn/zh-cn.js'
// import '../public/ue/ueditor.parse.min.js'
// import '../public/ue/themes/default/css/ueditor.css'

Vue.use(ElementUI)
Vue.use(Global)
Vue.prototype.$http=Http
Vue.prototype.$qs=Qs

Vue.config.productionTip = false



new Vue({
  render: h => h(App),
  router
}).$mount('#app')
