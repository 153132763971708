<template>
    <el-page-header @back="goBack" :content="this.title">
    </el-page-header>
</template>

<script>
    export default {
        props: ['title'],
        methods: {
            goBack() {
                this.$router.go(-1);
            }
        }
    }
</script>
