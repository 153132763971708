<template>
  <div style="background: #ffffff">
    <div style="padding: 20px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统设置</el-breadcrumb-item>
        <el-breadcrumb-item>角色管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="title_serch">
      <el-date-picker
        class="flex_item"
        value-format="yyyy-MM-dd"
        v-model="search.where1"
        type="daterange"
        align="left"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style=""
      >
      </el-date-picker>
      <el-input
        class="flex_item"
        style="width: 150px"
        v-model="search.where2"
        clearable
        placeholder="请输入角色名"
      ></el-input>
      <el-button
        class="flex_item"
        @click="search_load"
        type="primary"
        icon="el-icon-search"
        >搜索</el-button
      >
    </div>
    <div class="serch_group">
      <el-button @click="add" size="mini" type="primary" icon="el-icon-plus"
        >添加</el-button
      >
      <el-button
        @click="whole_del"
        size="mini"
        type="danger"
        icon="el-icon-delete"
        >删除</el-button
      >
    </div>
    <div style="padding: 0px 20px">
      <el-table
        :data="tableData"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="expand"> </el-table-column>
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="编号" width=""> </el-table-column>
        <el-table-column prop="title" label="角色名" width="">
        </el-table-column>
        <el-table-column prop="status" label="状态" width=""> </el-table-column>
        <el-table-column prop="time" label="创建时间" width="">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              @click="handleClick('update', scope.row, scope.$index)"
              type="primary"
              icon="el-icon-edit"
              size="mini"
            ></el-button>
            <el-button
              @click="handleClick('del', scope.row, scope.$index)"
              type="danger"
              icon="el-icon-delete"
              size="mini"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <Page :page="config_page"></Page>
    </div>
  </div>
</template>
<script>
import Page from "@/components/public/page.vue";
export default {
  components: {
    Page,
  },
  data() {
    return {
      tableData: [],
      // dataTable_height:600,
      search: {
        where1: "",
        where2: "",
        where3: "",
      },
      // 多选
      multipleSelection: [],
      // 分页对象
      config_page: {
        layout: "prev, pager, next,sizes,total",
        total: 1,
        page_sizi: 10,
        current_page: 1,
        page_sizes: [10, 20, 30, 40, 50, 100],
      },
    };
  },
  methods: {
    // 获取分页
    get_page() {
      return {
        current_page: this.config_page.current_page,
        page_sizi: this.config_page.page_sizi,
      };
    },
    // 分页重载
    page_load() {
      let where = {};
      Object.assign(where, this.get_page(), this.search);
      this.list_load(where);
    },
    // 搜索重载
    search_load() {
      let where = {};
      Object.assign(where, this.get_page(), this.search);
    //   where.current_page = 1;
      window.console.log(where);
      this.list_load(where);
    },
    // 重载数据
    list_load(param = {}) {
      window.console.log(param);
      this.$http
        .get("/admin/role", {
          params: param,
        })
        .then((res) => {
          window.console.log(res.data.data);
          let data = res.data.data;
          this.config_page.total = data.total;
          this.config_page.page_sizi = data.page_sizi;
          this.config_page.current_page = data.current_page;

          if (!res.data.data.data.length && this.config_page.current_page > 1) {
            this.$back(
              0,
              "当搜索第" +
                this.config_page.current_page +
                "页" +
                "-无数据-再次搜索重置为上一页"
            );
            if (this.config_page.current_page > 1) {
              this.config_page.current_page = this.config_page.current_page - 1;
            } else {
              this.config_page.current_page = 1;
            }
          }
          this.tableData = data.data;
          window.console.log(this.config_page);
          // this.$back(res.data.code, res.data.msg)
          // this.$redirect(1000,'/home')
        });
    },

    // chkbox
    handleSelectionChange(val) {
      // window.console.log(this.multipleSelection, val)
      this.multipleSelection = val;
    },
    // 批量操作
    whole_del() {
      // window.console.log(this.multipleSelection)
      this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.del_all();
          // this.tableData.splice(index,1)
        })
        .catch(() => {
          this.$back(0, "已取消删除", "info");
        });
    },
    // 头部添加
    add() {
      this.$router.push({
        path: "/role_add",
      });
    },
    // 行内事件
    handleClick(k, data, index) {
      window.console.log(k, data, index);
      switch (k) {
        case "update":
          this.$router.push({
            path: "/role_edit",
            query: {
              id: data.id,
            },
          });
          break;
        case "del":
          this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              // this.tableData.splice(index, 1)
              this.del(data.id);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
      }
    },
    // 批量删除
    del_all() {
      var arr = [];
      this.multipleSelection.forEach((item) => {
        window.console.log(item);
        arr.push(item.id);
      });
      if (arr.length == 0) {
        this.$back(0, "请选择操作项");
        return false;
      }
      this.$http
        .post(
          "/admin/role_del_all",
          this.$qs.stringify({
            id: arr,
          })
        )
        .then((res) => {
          this.$back(res.data.code, res.data.msg);
          this.search_load();
        })
        .catch((error) => {
          this.$back(0, error);
        });
    },
    // 行内删除
    del(id) {
      this.$http
        .post(
          "/admin/role_del",
          this.$qs.stringify({
            id: id,
          })
        )
        .then((res) => {
          this.$back(res.data.code, res.data.msg);
          this.search_load();
        })
        .catch((error) => {
          this.$back(0, error);
        });
    },
  },
  created() {
    // 初始化数据
    this.list_load(this.get_page());
  },
};
</script>
<style>
.title_serch {
  display: flex;
  padding: 20px 25px;
}

.flex_item {
  margin-right: 20px;
}

.serch_group {
  display: flex;
  padding: 10px 25px;
}
</style>
