<template>
  <div>
    <el-tree
      :data="data"
      
      show-checkbox
      :default-checked-keys="check_list"
      node-key="id"
      ref="tree"
      highlight-current
      @check="node_click()"
      :props="defaultProps"
    ></el-tree>
    <div class="buttons">
      <!-- <el-button @click="getCheckedNodes">通过 node 获取</el-button> -->
      <!-- <el-button @click="getCheckedKeys">通过 key 获取</el-button> -->
      <!-- <el-button @click="setCheckedNodes">通过 node 设置</el-button> -->
      <!-- <el-button @click="setCheckedKeys">通过 key 设置</el-button> -->
      <!-- <el-button @click="resetChecked">清空</el-button> -->
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'check_list', 
    'defaultProps',
     'data'
    ],
  methods: {
    getCheckedNodes() {
      // console.log(this.$refs.tree.getCheckedNodes());
    },
    getCheckedKeys() {
      // console.log(this.$refs.tree.getCheckedKeys());
    },
    setCheckedNodes() {
      this.$refs.tree.setCheckedNodes([
        {
          id: 5,
          label: "二级 2-1"
        },
        {
          id: 9,
          label: "三级 1-1-1"
        }
      ]);
    },
    setCheckedKeys() {
      alert(1);
      this.$refs.tree.setCheckedKeys([3]);
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([]);
    },
    // 更新节点
    node_click() {
      var array_obj = this.$refs.tree.getCheckedKeys();
      this.$emit("update_check", array_obj);
    }
  },
  created() {},
  data() {
    return {};
  }
};
</script>