<template>
  <div>
    <el-menu
      mode="horizontal"
      class="el-menu-demo"
      @select="handleSelect"
      id="wrap"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      style="background-color=none"
    >
      <div class="nav_top_title">后台管理系统 <span style="padding:0 20px"></span><span class="el-icon-time"> &nbsp;{{date_times}}</span></div>

      <!-- <div>
        <el-menu-item index="bb">消息中心</el-menu-item>
      </div> -->

      <div>
        <el-tooltip
          class="item"
          effect="dark"
          content="点击展示菜单"
          placement="bottom"
        >
          <el-menu-item index="cc">
            <el-dropdown @command="change" trigger="click">
              <span class="el-dropdown-link">
                {{ user_name }}
                <span style="padding-left: 5px"></span>
                <el-avatar
                  src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
                ></el-avatar>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">修改密码</el-dropdown-item>
                <el-dropdown-item command="2">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-menu-item>
        </el-tooltip>
      </div>
    </el-menu>

    <!-- <div class="line" style="height:1px;background:#e6e6e6;"></div> -->
  </div>
</template>
<style>
.nav_top_title {
  position: absolute;
  left: 50px;
  line-height: 60px;
  color: #ffffff;
  font-size: 20px;
}
#wrap {
  display: flex;
  /* flex-direction: column-reverse; */
  justify-content: flex-end;
  border: none;
}
</style>
<script>
export default {
  data() {
    return {
      user_name: localStorage.user_name,
      date_times: "",
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      // if(key==1){
      //     this.$router.push({path:'/index'})
      // }
      if (key == "bb") {
        this.$back(0, "暂无");
      }
      window.console.log(key, keyPath);
    },
    change(key) {
      if (key == 1) {
        this.$router.push({ path: "/change_password" });
      }
      if (key == 2) {
        localStorage.token = "";
        this.$router.push({ path: "/login" });
      }
    },

    show_time() {
      var _this=this
      var date = new Date(); //创建一个日期对象的实例。这句代码这样理解,new代表创建，Date是一个时间对象，连起来就是创建对象，并用变量date接收，实例就是一个实实在在的东西，比如：老师让你去帮他拿一个文件，结果你的同桌去了，你们两呢都属于人这个对象，所以你拿和他拿都一样。
    
      var year = date.getFullYear(); //引用了年这个方法(功能，意思是能实现什么，能干什么)getFullYear
      var month = date.getMonth() + 1; //这里加一是因为我们观念上的月份和定义方法的月份不一样，定义方法的月份是0~11，我们观念上的月份呢是1~12，因此要加一
      var day = date.getDate();
      var hour = date.getHours();
      if (hour < 10) {
        hour = "0" + hour;
      } //这里用if语句的原因是当我们的时间走到一位数字时就会出现闪动，会使整个代码混乱，为了解决这一缺点在个位数前面用拼接的方法加一个0，这样就形成了两位数，这样就不会出现闪动。分钟和秒也是一样
      var minute = date.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      var second = date.getSeconds();
      if (second < 10) {
        second = "0" + second;
      }
      var time =
        year +
        "/" +
        month +
        "/" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      this.date_times = time;
      setTimeout(function(){
        _this.show_time()
      }, 1000);
       //一秒钟之后执行showTime.大家都知道，我们的HTML代码是从上往下执行的，当执行到shoutime时，会往下执行调用的函数，然后执行函数，又执行setTimeout方法，一直这样循环下去，就能实现时钟的不停跳动。
    },
  },
  created() {
    this.show_time();
  },
};
</script>