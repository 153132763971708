<template>
  <div >
    <el-container>
  <el-header class="top_style top_style_public">
         <div class="top_nav"> <topmenu /></div>
  </el-header>
  <el-container :style="'height:'+height+'px'">
    <el-aside class="test-5" style="width:220px;" >
        <el-menu style="border:0px" :default-active="this.$route.path" :router="true">
          <menutree :data="menu_data"></menutree>
        </el-menu>
    </el-aside>
    <el-container>
      <el-main>
          <router-view></router-view>
      </el-main>
      
    </el-container>
  </el-container>
</el-container>
   
    
    <!-- <div class="wrap" >
     
      <div class="contents">
        
      </div>
    </div> -->
    <!-- <el-footer
        style="line-height: 60px;background-color: #ffffff"
    >Footer 2019 vue-php-admin 后台管理系统</el-footer>-->
  </div>
</template>
<style>
</style>
<script>
// import leftmenu from "@/components/public/menu/left_menu.vue";
import menutree from "@/components/public/menu/menutree.vue";
import topmenu from "@/components/public/menu/top_menu.vue";

export default {
  components: {
    menutree,
    topmenu,
  },
  data() {
    return {
      top_style:"top_style",
      height: 800,
      menu_data: [],
    };
  },
  methods: {
    menu_get() {
      this.$http.get("/admin/menu_get").then((res) => {
        this.menu_data = res.data.data;
      });
    },
  },
  created() {
    this.height = document.documentElement.clientHeight-60;
    this.menu_get();
    window.addEventListener('resize',()=>{
      this.height = document.documentElement.clientHeight-60;
    })
  },
};
</script>
<style>
.top_style {
  padding: 0px;
  border: 0px;
  background-color:#545c64;
  -moz-box-shadow: 2px 2px 5px #e6e6e6;
  -webkit-box-shadow: 2px 2px 5px #e6e6e6;
  box-shadow: 2px 2px 5px #e6e6e6;
  z-index: 9;
}

/* 左侧混动条 */
  .test-5::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  }
  .test-5::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius   : 10px;
  background-color: skyblue;
  background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
  );
  }
  .test-5::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  }


</style>
