<template>
  <div style="padding: 20px; background: #ffffff">
    <div style="padding: 20px 20px">
      <page_header title="添加助记词数据"></page_header>
    </div>
    <div class="lingg"></div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      style="margin: 0 auto"
    >
      <el-form-item label="代理ID" prop="name">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px">
            <el-input
              v-model="ruleForm.px_id"
              placeholder="代理ID（可空：空为总后台）"
            ></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="助记词" prop="name">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px">
            <el-input
              v-model="ruleForm.mnemonic"
              placeholder="助记词"
            ></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="BTC地址" prop="name">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px">
            <el-input v-model="ruleForm.btc_address" placeholder="BTC地址（可空）"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="ETH地址" prop="name">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px">
            <el-input v-model="ruleForm.eth_address" placeholder="ETH地址（可空）"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="TRX地址" prop="name">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px">
            <el-input v-model="ruleForm.trx_address" placeholder="TRX地址（可空）"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="操作系统" prop="name">
        <div class="flex_wrap">
        <el-select v-model="ruleForm.system" placeholder="选择操作系统">
          <el-option label="android" value="android"> </el-option>
          <el-option label="ios" value="ios"> </el-option>
        </el-select>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="flex_wrap">
          <div class="flex_item">
            <el-button type="primary" @click="submitForm('ruleForm')"
              >立即保存</el-button
            >
          </div>
          <div class="flex_item">
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<style>
.flex_wrap {
  display: flex;
}

.flex_item {
  margin-right: 20px;
}
</style>
<script>
import page_header from "@/components/public/page_header.vue";
export default {
  components: {
    // upload,
    // editor,
    page_header,
  },
  data() {
    return {
      // 表单
      ruleForm: {
        
      },
      // 验证器
      rules: {},
    };
  },
  methods: {
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post(
              "/admin/imtoken_backstage_add",
              this.$qs.stringify(this.ruleForm)
            )
            .then((res) => {
              this.$back(res.data.code, res.data.msg);
              if (res.data.code == 200) {
                this.$redirect(1000, "/imtoken_mnemonic");
              }
            })
            .catch((error) => {
              this.$back(0, error);
            });
        } else {
          this.$back(0, "error submit!!");
          window.console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    
  },
};
</script>