<template>
<div class="menutree">
    <label v-for="menu in data" :key="menu.index">
    <el-submenu :index="menu.router_path" v-if="menu.children" v-show="menu.status==1">
        <template slot="title">
           <i :class="menu.icon"></i>
        <span>{{menu.router_name}}</span>
        </template>
        <label>
        <menutree :data="menu.children"></menutree>
        </label>
    </el-submenu>
    <el-menu-item v-else :index="menu.router_path" v-show="menu.status==1">
        <i :class="menu.icon"></i>
        <span slot="title">{{menu.router_name}}</span>
    </el-menu-item>
    </label>
</div>
</template>
<script>
import menutree from "@/components/public/menu/menutree";
export default {
name: "menutree",
data() {
    return {
    menu_data: {}
    };
},
components: {
    menutree
},
props: ["data"],
};
</script>