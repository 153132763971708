<template>
  <div id="app">
    <!--    <Home></Home>-->
    <!--    <router-link to="/home">111</router-link>-->
    <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Home from './view/base'

export default {
  name: "app",
  components: {
    // HelloWorld,
    // Home,
  },
  methods: {},
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  /*margin: 0px;*/
  /*padding: 0px;*/
  /*margin-top: 60px;*/
}
body {
  background-color: #ffffff;
  margin: 0px;
  padding: 0px;
}
html {
  /*width: 100%;*/
}
</style>
