<template>
	<div class="cont">
		<el-card class="box-card">

			<el-row :gutter="0">
				<el-col :span="24">登录</el-col>
				<el-col :span="24">
					<el-input clearable prefix-icon="el-icon-user" v-model="form.u_account" placeholder="请输入账号"></el-input>
				</el-col>
				<el-col :span="24">
					<el-input prefix-icon="el-icon-lock" show-password v-model="form.u_password" placeholder="请输入密码" @keyup.enter.native="submit"></el-input>
				</el-col>
				<el-col :span="24">
					<el-button type="primary" :loading="loading" @click="submit" style="width: 100%">登录</el-button>
				</el-col>

			</el-row>
		</el-card>
	</div>

</template>
<style>
	.box-card {
		width: 350px;
		position: fixed;
		left: 50%;
		top: 50%;
		/*height: 350px;*/
		margin-left: -200px;
		margin-top: -200px;
		box-sizing: border-box;
		padding: 20px 20px 60px 20px;


	}

	.el-col {
		padding-bottom: 20px;
	}

	.cont {
		width: 100%;
		height: 100%;
		position: fixed;
		background: url("../../assets/login.jpg") no-repeat;
		background-size: cover;
	}
</style>
<script>
	export default {
		components: {},
		data() {
			return {
				form: {
					u_account: "",
					u_password: ""
				},
				loading: false
			}
		},
		methods: {
			submit() {
				this.loading = true
				this.$http.post('/admin_login', this.$qs.stringify(this.form)).then(res => {
					this.loading = false
					this.$back(res.data.code, res.data.msg)
					if (res.data.code == 200) {
						localStorage.token = res.data.data.token
						localStorage.user_name = res.data.data.u_account
						this.$redirect(1000, '/log')
					} else {
						this.loading = false
					}
				}).catch(error => {
					this.$back(0,error)
					this.loading = false
				})
			}
		}
	}
</script>
