<template>
  <div style="padding: 20px;background: #ffffff">
    <div style="padding: 20px 20px">
      <page_header title="菜单添加"></page_header>
    </div>
    <div class="lingg"></div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      style="margin: 0 auto"
    >
     <el-form-item label="上级菜单" prop="pid">
        <div class="flex_wrap">
          
          <div class="flex_item" style="width: 400px;padding-top:6px">
            <selecttree
              @update_check="update_check"
              :check_list="[ruleForm.pid]"
              :data="tree"
              :defaultProps=" {children: 'childs',label: 'title'}"
            />
          </div>
        </div>
      </el-form-item>
      <el-form-item label="菜单标题" prop="title">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px;">
            <el-input v-model="ruleForm.title" placeholder="请输入菜单名称"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px;">
            <el-input type="num" v-model="ruleForm.sort" placeholder="输入排序"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="图标" prop="icon">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px;">
            <el-input v-model="ruleForm.icon" placeholder="请输入图标"></el-input>
          </div>
          <div class="flex_item">
            <el-link
              type="primary"
              target="_blank"
              href="https://element.eleme.cn/#/zh-CN/component/icon#tu-biao-ji-he"
            >选择图标</el-link>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="路由名称" prop="router_name">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px;">
            <el-input v-model="ruleForm.router_name" placeholder="请输入路由名称"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="路由地址" prop="router_path">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px;">
            <el-input v-model="ruleForm.router_path" placeholder="请输入路由地址"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <div class="flex_wrap">
          <div class="flex_item">
            <el-radio-group v-model="ruleForm.status" style="padding: 10px">
              <el-radio :label="1">显示</el-radio>
              <el-radio :label="0">隐藏</el-radio>
            </el-radio-group>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="类型" prop="types">
        <div class="flex_wrap">
          <div class="flex_item">
            <el-radio-group v-model="ruleForm.types" style="padding: 10px">
              <el-radio :label="1">菜单</el-radio>
              <el-radio :label="2">接口</el-radio>
            </el-radio-group>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="flex_wrap">
          <div class="flex_item">
            <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          </div>
          <div class="flex_item">
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<style>
.flex_wrap {
  display: flex;
}

.flex_item {
  margin-right: 20px;
}
</style>
<script>
import page_header from "@/components/public/page_header.vue";
import selecttree from "@/components/public/selectTree.vue";
export default {
  components: {
    page_header,
    selecttree
  },
  data() {
    return {
      ruleForm: {
        id: "",
        title: "",
        sort: "",
        pid: 1,
        icon: "",
        router_name: "",
        router_path: "",
        status: 1,
        types: 1
      },
      tree: [],
      // 验证器
      rules: {}
    };
  },
  methods: {
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$http
            .post("/admin/rule_add", this.$qs.stringify(this.ruleForm))
            .then(res => {
              this.$back(res.data.code, res.data.msg);
              // if (res.data.code == 200) {
              //   this.$redirect(1000, "/rule");
              // }
            })
            .catch(error => {
              this.$back(0, error);
            });
        } else {
          this.$back(0, "error submit!!");
          window.console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取上级菜单
    tree_get() {
      this.$http.post("/admin/rule_tree").then(res => {
        this.tree = res.data.data;
      });
    },
    // 更新tree
    update_check(arr){
        if(arr.length>0){
          this.ruleForm.pid=arr[0]
        }
    },
    handleNodeClick(data) {
      window.console.log(data);
    }
  },
  created() {
    this.tree_get();
    if(this.$route.query.id){
    this.ruleForm.pid=this.$route.query.id

    }
  }
};
</script>
