<template>
	<div style="padding: 20px;background: #ffffff">
		<div style="padding: 20px 20px">
			<page_header title="用户编辑"></page_header>
		</div>
		<div class="lingg"></div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" style="margin: 0 auto">
			<el-form-item label="账号" prop="u_account">
				<div class="flex_wrap">
					<div class="flex_item" style="width: 440px;">
						<el-input v-model="ruleForm.u_account" placeholder="请输入账号"></el-input>
					</div>
				</div>
			</el-form-item>
			<!-- <el-form-item label="密码" prop="u_password">
				<div class="flex_wrap">
					<div class="flex_item" style="width: 440px;">
						<el-input type="password" v-model="ruleForm.u_password" placeholder="请输入密码"></el-input>
					</div>
				</div>
			</el-form-item> -->
			<el-form-item label="昵称" prop="u_name">
				<div class="flex_wrap">
					<div class="flex_item" style="width: 440px;">
						<el-input v-model="ruleForm.u_name" placeholder="请输入昵称"></el-input>
					</div>
				</div>
			</el-form-item>
			<el-form-item label="手机号" prop="u_tel">
				<div class="flex_wrap">
					<div class="flex_item" style="width: 440px;">
						<el-input v-model="ruleForm.u_tel" placeholder="请输入手机号"></el-input>
					</div>
				</div>
			</el-form-item>
			<el-form-item label="邮箱" prop="u_email">
				<div class="flex_wrap">
					<div class="flex_item" style="width: 440px;">
						<el-input v-model="ruleForm.u_email" placeholder="请输入邮箱"></el-input>
					</div>
				</div>
			</el-form-item>

			<el-form-item>
				<div class="flex_wrap">
					<div class="flex_item">
						<el-button type="primary" @click="submitForm('ruleForm')">立即更新</el-button>
					</div>
					<div class="flex_item">
						<el-button @click="resetForm('ruleForm')">重置</el-button>
					</div>
				</div>

			</el-form-item>
		</el-form>

	</div>
</template>
<style>
	.flex_wrap {
		display: flex;
	}

	.flex_item {
		margin-right: 20px;
	}
</style>
<script>
	import page_header from '@/components/public/page_header.vue'
	export default {
		components: {
			page_header
		},
		data() {
			return {
				ruleForm: {
					u_account: '',
					u_password: '',
					u_name: '',
					u_tel: '',
					u_email: '',
				},
				// 上传对象
				upload: {
					upload_url: this.BaseUrl + '/upload',
					list_type: "picture-card",
					limit: 1,
					file_type: ['jpg', 'png'],
					file_sizi: 200,
					img_arr: []
				},

				// 验证器
				rules: {
					u_account: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}, ],
					u_password: [{
							required: true,
							message: '请输入密码',
							trigger: 'change'
						},
						{
							min: 6,
							message: '最少输入6位密码',
							trigger: 'blur'
						}
					],
					u_name: [{
						required: true,
						message: '请输入昵称',
						trigger: 'blur'
					}],
					u_tel: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							min: 11,
							message: '请输入11位手机号',
							trigger: 'blur'
						}
					],
					u_email: [{
						required: true,
						message: '请输入邮箱',
						trigger: 'blur'
					}],

				}
			};
		},
		methods: {
			// 提交
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$http.post('/admin/user_update', this.$qs.stringify(this.ruleForm)).then(res => {
							this.$back(res.data.code, res.data.msg)
							if (res.data.code == 200) {
								this.$redirect(1000, '/user')
							}
						}).catch(error => {
							this.$back(0, error)
						})
					} else {
						this.$back(0, 'error submit!!')
						window.console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
		},
		created() {
			this.$http.post('/admin/user_edit', this.$qs.stringify({id:this.$route.query.id})).then(res => {
				if (res.data.code == 200) {
					this.ruleForm = res.data.data
				}
			}).catch(error => {
				this.$back(0, error)
			})

	}
	}
</script>
