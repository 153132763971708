<template>
  <div style="background: #ffffff">
    <div style="padding: 20px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统设置</el-breadcrumb-item>
        <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="serch_group">
      <el-button @click="add" size="mini" type="primary" icon="el-icon-plus">添加</el-button>

      <!-- <el-button @click="whole_del" size="mini" type="danger" icon="el-icon-delete">删除</el-button> -->
    </div>
    <div style="padding:0px 20px">
      <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        :default-expand-all="true"
        :tree-props="{children: 'childs', hasChildren: 'hasChildren'}"
      >
        <el-table-column prop="title" label="菜单名称" width="300"></el-table-column>
        <el-table-column prop="icon" label="图标" width></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="router_path" label="路径"></el-table-column>
        <el-table-column prop="status" label="可见"></el-table-column>
        <el-table-column prop="status" label="类型">
           <template slot-scope="scope">
          <el-tag :disable-transitions="false" effect="dark" :type="scope.row.types=='菜单' ? '':'danger'">
            {{scope.row.types}}
          </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="time" label="创建时间"></el-table-column>

        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              @click="handleClick('update',scope.row,scope.$index)"
              type="primary"
              icon="el-icon-edit"
              size="mini"
            ></el-button>
            <el-button
              @click="handleClick('create',scope.row,scope.$index)"
              type="success"
              icon="el-icon-plus"
              size="mini"
            ></el-button>
            <el-button
              @click="handleClick('del',scope.row,scope.$index)"
              type="danger"
              icon="el-icon-delete"
              size="mini"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tableData: []
    };
  },
  methods: {
    // 重载数据
    list_load(param = {}) {
      window.console.log(param);
      this.$http
        .get("/admin/rule", {
          params: param
        })
        .then(res => {
          window.console.log(res.data.data);
          this.tableData = res.data.data;
        });
    }, // 行内事件
    handleClick(k, data, index) {
      window.console.log(k, data, index);
      switch (k) {
        case "update":
          this.$router.push({
            path: "/rule_edit",
            query: {
              id: data.id
            }
          });
          break;
        case "create":
          this.$router.push({
            path: "/rule_add",
            query: {
              id: data.id
            }
          });
          break;
        case "del":
          this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              // this.tableData.splice(index, 1)
              this.del(data.id);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除"
              });
            });
          break;
      }
    },
    add() {
      this.$router.push({
        path: "/rule_add"
      });
    },
    del(id) {
      this.$http
        .post(
          "/admin/rule_del",
          this.$qs.stringify({
            id: id
          })
        )
        .then(res => {
          this.$back(res.data.code, res.data.msg);
          this.list_load();
        })
        .catch(error => {
          this.$back(0, error);
        });
    }
  },
  created() {
    // 初始化数据
    this.list_load();
    // 初始化高度
  }
};
</script>
<style>
.title_serch {
  display: flex;
  padding: 20px 25px;
}

.flex_item {
  margin-right: 20px;
}

.serch_group {
  display: flex;
  padding: 10px 25px;
}
</style>
