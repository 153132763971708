<template>
    <div
            style="padding: 20px 0px;width:100%;background: #ffffff"
    >
        <el-pagination
                background
                :layout="page.layout"
                :page-size="page.pag_sizi"
                :page-sizes="page.page_sizes"
                @size-change="size_change"
                @current-change="current_change"
                @prev-click="prevs"
                @next-click="nexts"
                :hide-on-single-page="true"
                :total="page.total">
        </el-pagination>
    </div>

</template>

<script>
    export default {
        props: {
            page: {}
        },
        data() {
            return {
                // page: {
                //     layout: 'prev, pager, next,sizes,total',
                //     total: 0,
                //     page_sizi: 10,
                //     current_page: 1,
                //     page_sizes: [10, 20, 30, 40, 50, 100]
                //
                // }
            }
        },
        methods: {
            size_change(size) {
                this.page.page_sizi = size
                this.$parent.page_load(this.page_get())
            },
            current_change(current) {
                this.page.current_page = current
                this.$parent.page_load(this.page_get())

            },
            prevs(current) {
                this.page.current_page = current
                this.$parent.page_load(this.page_get())
            },
            nexts(current) {
                this.page.current_page = current
                this.$parent.page_load(this.page_get())
            },
            page_get(){
                return {
                    page_sizi:this.page.page_sizi,
                    current_page:this.page.current_page,

                }
            },

        },
    }
</script>

<style>
</style>
