<template>
  <div>
    <el-tree
      :data="data"
      check-strictly
      show-checkbox
      :default-checked-keys="check_list"
      node-key="id"
      ref="tree"
      highlight-current
      @check-change="handleClick"
      @node-click="node_click()"
      :props="defaultProps"
    ></el-tree>
    <div class="buttons">
      <!-- <el-button @click="getCheckedNodes">通过 node 获取</el-button> -->
      <!-- <el-button @click="getCheckedKeys">通过 key 获取</el-button> -->
      <!-- <el-button @click="setCheckedNodes">通过 node 设置</el-button> -->
      <!-- <el-button @click="setCheckedKeys">通过 key 设置</el-button> -->
      <!-- <el-button @click="resetChecked">清空</el-button> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ["check_list", "defaultProps", "data",'redis_type'],
  methods: {
    getCheckedNodes() {
      // console.log(this.$refs.tree.getCheckedNodes());
    },
    getCheckedKeys() {
      // console.log(this.$refs.tree.getCheckedKeys());
    },
    setCheckedNodes() {
      this.$refs.tree.setCheckedNodes([
        {
          id: 5,
          label: "二级 2-1"
        },
        {
          id: 9,
          label: "三级 1-1-1"
        }
      ]);
    },
    setCheckedKeys() {
      // alert(1);
      this.$refs.tree.setCheckedKeys([3]);
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([]);
    },
    // 更新节点
    node_click() {
      // alert(1);
      // window.console.log(this.$refs.tree.getCheckedKeys());
      // this.$emit("update_check", data);
    },
    handleClick(data, checked) {
      this.i++;
      if (this.i % 2 === 0) {
        if (checked) {
          this.$refs.tree.setCheckedNodes([]);
          this.$refs.tree.setCheckedNodes([data]);
          //交叉点击节点
        } else {
          this.$refs.tree.setCheckedNodes([]);
          //点击已经选中的节点，置空
        }
      this.$emit("update_check", this.$refs.tree.getCheckedKeys());
      }
    }
  },
  created() {
     
  },
  data() {
    return {
      i:1
    };
  }
};
</script>