import axios from 'axios'
import router from "./router";
import { Loading } from 'element-ui';
import { Message } from 'element-ui';
let loaging;
let BaseUrl = 'https://api.iim-token.me'
function startLoading() {
    loaging = Loading.service({
        lock: true,
        text: '拼命加载中...',
        background: 'rgba(0,0,0,0.6)',
        spinner: 'el-icon-loading'
    })
}
function endLoanding() {
    loaging.close();
}
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
axios.interceptors.request.use(
    config => {
        startLoading()
        let token = localStorage.token;
        config.headers.token = `${token}`;
        config.url = BaseUrl + config.url
        return config;
    },
    err => {

        endLoanding();
        return Promise.reject(err);
    });
axios.interceptors.response.use(res => {

    if (res.data.code == 401) {
        Message({
            message: res.data.msg,
            type: 'error'
        });

        setTimeout(() => {
            router.push({ path: '/login' })
        }, 500);
    }
    if (res.data.code == 403) {
        Message({
            message: res.data.msg,
            type: 'error'
        });
    }
    setTimeout(() => {
        endLoanding();
    }, 200);
    return res
},
    error => {
        // alert(res)
        endLoanding();
        // Message({
        //     type:'error',
        //     message:"接口错误"
        // })
        return Promise.reject(error)
    })
axios.defaults.timeout = 10000;
export default axios
