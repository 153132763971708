<template>
  <div style="background: #ffffff">
  
      <div style="padding: 20px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统设置</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="title_serch">
      <el-date-picker
        class="flex_item"
        value-format="yyyy-MM-dd"
        v-model="search.where1"
        type="daterange"
        align="left"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style
      ></el-date-picker>
      <el-input
        class="flex_item"
        style="width: 150px"
        v-model="search.where2"
        clearable
        placeholder="请输入账号"
      ></el-input>
      <el-input
        class="flex_item"
        style="width: 150px"
        v-model="search.where3"
        clearable
        placeholder="请输入手机号"
      ></el-input>

      <el-button
      ref="searchs"
        class="flex_item"
        @click="search_load"
        type="primary"
        icon="el-icon-search"
        >搜索</el-button

        
      >
    </div>

    <div class="serch_group">
      <el-button @click="$new_pages('/user_add')" size="mini" type="primary" icon="el-icon-plus"
        >添加</el-button
      >
      <el-button
        @click="whole_del"
        size="mini"
        type="danger"
        icon="el-icon-delete"
        >删除</el-button
      >
    </div>
    <div style="padding: 0px 20px">
      <el-table
        :data="tableData"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
               <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="邮箱">
                <span>{{ props.row.u_email }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>

        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="u_id" label="编号" width></el-table-column>
        <el-table-column prop="u_account" label="账号" width></el-table-column>
        <el-table-column prop="u_name" label="昵称" width></el-table-column>
        <el-table-column prop="u_tel" label="手机号" width></el-table-column>
        <el-table-column prop="u_time" label="创建时间" width></el-table-column>
        <el-table-column prop="u_time" label="状态" width>
          <template slot-scope="scope">
            <el-switch
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="0"
              @change="user_status(scope.row)"
              v-model="scope.row.u_status"
              :disabled="scope.row.u_id == 1 ? true : false"
            ></el-switch>
          </template>
        </el-table-column>
        <template>
          <el-dialog
            title="重置密码"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="600px"
          >
            <el-form :model="form">
              <el-form-item label="密码" label-width>
                <el-input
                  v-model="form.u_password"
                  type="password"
                  autocomplete="off"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="reset_password()"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </template>
        <template>
          <el-dialog
            title="权限分配"
            :visible.sync="dialogFormVisible_role"
            :append-to-body="true"
            width="600px"
          >
            <el-form :model="role">
              <el-form-item label="权限" label-width>
                <el-select v-model="role.group_id" placeholder="请选择">
                  <el-option
                    v-for="item in role_group"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible_role = false"
                >取 消</el-button
              >
              <el-button type="primary" @click="role_submit()">确 定</el-button>
            </div>
          </el-dialog>
        </template>
        <el-table-column fixed="right" label="操作" width="230">
          <template slot-scope="scope">
            <el-button
              @click="handleClick('update', scope.row, scope.$index)"
              type="primary"
              icon="el-icon-edit"
              size="mini"
              :disabled="scope.row.u_id == 1 ? true : false"
            ></el-button>
            <el-button
              @click="show_pass(scope.row)"
              type="success"
              icon="el-icon-lock"
              size="mini"
              :disabled="scope.row.u_id == 1 ? true : false"
            ></el-button>
            <el-button
              @click="show_role(scope.row)"
              type="warning"
              icon="el-icon-user"
              size="mini"
              :disabled="scope.row.u_id == 1 ? true : false"
            ></el-button>

            <el-button
              @click="handleClick('del', scope.row, scope.$index)"
              type="danger"
              icon="el-icon-delete"
              size="mini"
              :disabled="scope.row.u_id == 1 ? true : false"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <Page :page="config_page"></Page>
    </div>
  </div>
</template>

<script>
import Page from "@/components/public/page.vue";

export default {
  components: {
    Page,
  },
  data() {
    return {
      // 重置密码
      form: {
        u_password: "",
        u_id: "",
      },
      //   角色获取
      role_group: [],
      //   角色选择
      role: {
        group_id: "",
        uid: "",
      },
      tableData: [],
      //   模态窗口
      dialogFormVisible: false,
      dialogFormVisible_role: false,
      //   formLabelWidth: "120px",
      search: {
        where1: "",
        where2: "",
        where3: "",
      },
      // 多选
      multipleSelection: [],
      // 分页对象
      config_page: {
        layout: "prev, pager, next,sizes,total",
        total: 1,
        page_sizi: 10,
        current_page: 1,
        page_sizes: [10, 20, 30, 40, 50, 100],
      },
    };
  },
  methods: {
    // 获取分页
    get_page() {
      return {
        current_page: this.config_page.current_page,
        page_sizi: this.config_page.page_sizi,
      };
    },
    // 分页重载
    page_load() {
      let where = {};
      Object.assign(where, this.get_page(), this.search);
      this.list_load(where);
    },
    // 搜索重载
    search_load() {
      let where = {};
      Object.assign(where, this.get_page(), this.search);

      // if(this.tableData){

      // }
      // where.current_page = 1;
      // window.console.log(where,'111111111');
      this.list_load(where);
    },
    // 重载数据
    list_load(param = {}) {
      window.console.log(param);
      this.$http
        .get("/admin/user", {
          params: param,
        })
        .then((res) => {
          window.console.log(res.data.data);
          let data = res.data.data;
          this.config_page.total = data.total;
          this.config_page.page_sizi = data.page_sizi;
          this.config_page.current_page = data.current_page;

          // alert(this.config_page.total/this.config_page.page_sizi)

          if (!res.data.data.data.length && this.config_page.current_page > 1) {
            this.$back(
              0,
              "当搜索第" +
                this.config_page.current_page +
                "页" +
                 "-无数据-自动返回上一页"
            );
            if (this.config_page.current_page > 1) {
              this.config_page.current_page = this.config_page.current_page - 1;

              this.$refs.searchs.$el.click();
            } else {
              this.config_page.current_page = 1;
            }
          }
          this.tableData = data.data;
          // this.page_load()

          window.console.log(this.config_page);
        });
    },

    // chkbox
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量操作
    whole_del() {
      // window.console.log(this.multipleSelection)
      this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.del_all();
          // this.tableData.splice(index,1)
        })
        .catch(() => {
          this.$back(0, "已取消删除", "info");
        });
    },
   
    // 行内事件
    handleClick(k, data, index) {
      window.console.log(k, data, index);
      switch (k) {
        case "update":
    
          this.$new_pages('/user_edit',{
              id: data.u_id,
            })
          break;
        case "del":
          this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              // this.tableData.splice(index, 1)
              this.del(data.u_id);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
      }
    },
    del_all() {
      var arr = [];
      this.multipleSelection.forEach((item) => {
        window.console.log(item);
        arr.push(item.u_id);
      });
      if (arr.length == 0) {
        this.$back(0, "请选择操作项");
        return false;
      }
      this.$http
        .post("/admin/user_del_all", this.$qs.stringify({ id: arr }))
        .then((res) => {
          this.$back(res.data.code, res.data.msg);
          this.search_load();
        })
        .catch((error) => {
          this.$back(0, error);
        });
    },
    del(id) {
      this.$http
        .post("/admin/user_del", this.$qs.stringify({ id: id }))
        .then((res) => {
          this.$back(res.data.code, res.data.msg);
          this.search_load();
        })
        .catch((error) => {
          this.$back(0, error);
        });
    },
    // 用户状态
    user_status(data) {
      this.$http
        .post(
          "/admin/user_status",
          this.$qs.stringify({ u_id: data.u_id, u_status: data.u_status })
        )
        .then((res) => {
          this.$back(res.data.code, res.data.msg);
          //   this.list_load();
        })
        .catch((error) => {
          this.$back(0, error);
        });
    },
    // 密码重置显示
    show_pass(data) {
      this.dialogFormVisible = true;
      this.form.u_id = data.u_id;
    },
    // 密码重置
    reset_password(k, data, index) {
      window.console.log(k, data, index);
      this.$http
        .post("/admin/reset_password", this.$qs.stringify(this.form))
        .then((res) => {
          this.$back(res.data.code, res.data.msg);
          this.dialogFormVisible = false;
          this.form.u_password = "";
        })
        .catch((error) => {
          this.$back(0, error);
        });
    },
    //角色显示
    show_role(data) {
      this.role.uid = data.u_id;
      this.$http
        .post("/admin/role_group", this.$qs.stringify({ id: data.u_id }))
        .then((res) => {
          //   this.$back(res.data.code, res.data.msg);
          this.role_group = res.data.data.data;
          this.role.group_id = res.data.data.group_id;
          this.dialogFormVisible_role = true;
        })
        .catch((error) => {
          this.$back(0, error);
        });
    },
    // 角色分配
    role_submit() {
      this.$http
        .post("/admin/user_role", this.$qs.stringify(this.role))
        .then((res) => {
          this.$back(res.data.code, res.data.msg);
          this.dialogFormVisible_role = false;
        })
        .catch((error) => {
          this.$back(0, error);
        });
    },
  },
  created() {
    // 初始化数据
    this.list_load(this.get_page());
  },
};
</script>
<style>
.title_serch {
  display: flex;
  padding: 20px 25px;
}

.flex_item {
  margin-right: 20px;
}

.serch_group {
  display: flex;
  padding: 10px 25px;
}

</style>
