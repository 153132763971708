<template>
  <div style="padding: 20px;background: #ffffff">
    <div style="padding: 20px 20px">
      <page_header title="角色更新"></page_header>
    </div>
    <div class="lingg"></div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      style="margin: 0 auto"
    >
      <el-form-item label="账号" prop="">
        <div class="flex_wrap">
          <div class="flex_item" style="width: 440px;">
            <el-input v-model="ruleForm.title" placeholder="请输入角色名称"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="状态" prop="">
        <div class="flex_wrap">
          <div class="flex_item">
            <el-radio-group v-model="ruleForm.status" style="padding: 10px">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="选择权限" prop=''>
        <div class="flex_wrap">
          <div class="flex_item" style="width:440px">
            <tree
              @update_check="update_check"
              :check_list="ruleForm.rule"
              :data="tree_list"
              :defaultProps=" {children: 'childs',label: 'title'}"
            ></tree>
          </div>
        </div>
      </el-form-item>

      <el-form-item>
        <div class="flex_wrap">
          <div class="flex_item">
            <el-button type="primary" @click="submitForm('ruleForm')">立即更新</el-button>
          </div>
          <div class="flex_item">
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<style>
.flex_wrap {
  display: flex;
}

.flex_item {
  margin-right: 20px;
}
</style>
<script>
import page_header from "@/components/public/page_header.vue";
import tree from "@/components/public/select_trees.vue";

export default {
  components: {
    page_header,
    tree
  },
  data() {
    return {
      ruleForm: {
        status: 1,
        title: "",
        rule: []
      },
      tree_list: [],

      upload: {
        upload_url: this.BaseUrl + "/upload",
        list_type: "picture-card",
        limit: 1,
        file_type: ["jpg", "png"],
        file_sizi: 200,
        img_arr: []
      },

      // 验证器
      rules: {
    
      }
    };
  },
  methods: {
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$http
            .post("/admin/role_update", this.$qs.stringify(this.ruleForm))
            .then(res => {
              this.$back(res.data.code, res.data.msg);
              if (res.data.code == 200) {
                this.$redirect(1000, "/role");
              }
            })
            .catch(error => {
              this.$back(0, error);
            });
        } else {
          this.$back(0, "error submit!!");
          window.console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 更新选中
    update_check(arr) {
      this.ruleForm.rule=arr
    },
    // 初始化tree
    tree_load() {
      this.$http.post("/admin/role_rule").then(res => {
        this.tree_list = res.data.data;
      });
      window.console.log(this.tree_list);
    },
    // 获取原数据
    role_get() {
      this.$http.post("/admin/role_edit",this.$qs.stringify({id:this.$route.query.id})).then(res => {
        this.ruleForm = res.data.data;
      });
    }
  },
  created() {
    this.role_get();
    this.tree_load()
  }
};
</script>
