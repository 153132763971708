<template>
    <div style="padding: 20px;background: #ffffff">
        <div style="padding: 20px 20px">
            <page_header title="修改密码"></page_header>

        </div>
        <div class="lingg"></div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                 style="margin: 0 auto">
            <el-form-item label="旧密码" prop="password">
                <el-col :span="11">
                    <el-input type="password" v-model="ruleForm.password"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="新密码" prop="new_password">
                <el-col :span="11">
                    <el-input type="password" v-model="ruleForm.new_password"></el-input>
                </el-col>
            </el-form-item>
<!--            <el-form-item label="活动形式">-->


<!--            </el-form-item>-->
<!--            <div style="overflow: hidden;padding-bottom: 50px">-->
<!--                <div style="width: 100px;float: left">-->
<!--                    编辑器-->
<!--                </div>-->
<!--                <div style="float: left;width: 800px">-->
<!--                    <editor ref="edit"></editor>-->

<!--                </div>-->

<!--            </div>-->

            <el-form-item>
                <el-col :span="11" style="">
                    <el-button type="primary" @click="submitForm('ruleForm')" class="input_left">立即保存</el-button>
<!--                    <el-button @click="resetForm('ruleForm')" class="input_left">重置</el-button>-->
                </el-col>
            </el-form-item>
        </el-form>

    </div>
</template>
<style>
    .input_left {
        float: left;
    }

     .el-tag + .el-tag {
         margin-left: 10px;
     }
    .button-new-tag {
        /*margin-left: 10px;*/
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
</style>
<script>
    export default {
        components:{
        },
        data() {
            return {
                inputVisible: false,
                inputValue: '',
                ruleForm: {
                    password: '',
                    new_password: '',
                },

                // 验证器
                rules: {
                    password: [
                        {required: true, message: '请输入旧密码', trigger: 'blur'},
                    ],
                    new_password: [
                        {required: true, message: '请输入新密码', trigger: 'blur'},
                    ],


                }
            };
        },
        methods: {
            // 提交
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        // alert(1)
                        this.$http.post('/admin/change_password', this.$qs.stringify(this.ruleForm)).then(res => {
                            this.$back(res.data.code,res.data.msg)
                           if (res.data.code==200){
                               localStorage.token=''
                               this.$redirect(1000,'/login',)
                           }
                        }).catch(error=>{
                            this.$back(0,error)
                        })
                        // alert('submit!');
                    } else {
                        window.console.log('error submit!!');
                        this.$back(0,'error submit!!')
                        return false;
                    }
                });
            },

        },
        created() {
        }
    }
</script>
