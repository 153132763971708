<template>
  <div id="cont">
    <div>
      <template>
        <el-dialog
          title="添加任务"
          :visible.sync="dialogFormVisible"
          :append-to-body="true"
          width="600px"
        >
          <el-form :model="form">
            <el-form-item label="任务" label-width>
              <el-input v-model="form.msg" autocomplete="off" placeholder="请输入任务详细"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="subimit_msg()">确 定</el-button>
          </div>
        </el-dialog>
      </template>
      <el-calendar>
        <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
        <template slot="dateCell" slot-scope="{date, data}">
          <div class="text_cent">
            <div class="calendar_num">
              <p :class="data.isSelected ? 'is-selected' : ''">
                {{ data.day.split('-').slice(1)[1] }}
                <!-- {{ data.isSelected ? '✔️' : ''}} -->
              </p>
            </div>
            <div class="calendar_but">
              <el-button
                type="primary"
                @click.stop="add(data.day)"
                icon="el-icon-plus"
                circle
                size="mini"
              ></el-button>
            </div>
          </div>

          <div>
            <el-badge
              :value="remind_get(data.day)"
              class="item"
              type="warning"
              v-show="remind_get(data.day)>0"
            >
              <el-dropdown>
                <span class="el-dropdown-link">
                  我的任务
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-show="data.day==item.date"
                    v-for="(item,k) in remind"
                    :key="k"
                  >
                    {{item.msg}}
                    <span style="padding-left:15px"></span>
                    <el-button type="text" icon="el-icon-delete" @click="remind_del(item.id)"></el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-badge>
          </div>
        </template>
      </el-calendar>
    </div>
    <div id="bottom">
      <div style="width:300px">
      </div>
      <div></div>
    </div>
  </div>
</template>
<style>
.text_cent {
  display: flex;
}
.el-dropdown-link {
  color: #409eff;
}
.is-selected {
  color: #1989fa;
}
.calendar_num {
  margin-left: 10%;
}
.calendar_but {
  padding-top: 10px;
  margin-left: 15%;
}
#bottom {
  /* display: flex; */
}
#cont {
  /* display: flex; */
}
</style>
<script>
export default {
  components:{
  },
  data() {
    return {
      // 模态
      dialogFormVisible: false,
      // 添加任务
      form: {
        msg: "",
        date: ""
      },
      // 任务列表
      remind: []
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      window.console.log(key, keyPath);
    },
    // 添加任务
    add(date) {
      this.form.date = date;
      this.dialogFormVisible = true;
    },
    // 日历任务获取
    calendar() {
      this.$http
        .get("/admin/remind_get", this.$qs.stringify(this.form))
        .then(res => {
          //   this.$back(res.data.code, res.data.msg);
          this.remind = res.data.data;
        })
        .catch(error => {
          this.$back(0, error);
        });
    },
    // 日历任务提交
    subimit_msg() {
      this.$http
        .post("/admin/remind_add", this.$qs.stringify(this.form))
        .then(res => {
          this.$back(res.data.code, res.data.msg);
          this.dialogFormVisible = false;
          this.form.msg = "";
          this.calendar();
        })
        .catch(error => {
          this.$back(0, error);
        });
    },
    // 任务数量
    remind_get(date) {
      let number = 0;
      this.remind.forEach(item => {
        if (item.date == date) {
          number++;
        }
      });
      return number;
    },
    // 删除任务
    remind_del(id) {
      this.$http
        .post("/admin/remind_del", this.$qs.stringify({ id: id }))
        .then(res => {
          this.$back(res.data.code, res.data.msg);
          this.calendar();
        })
        .catch(error => {
          this.$back(0, error);
        });
    }
  },
  created() {
    this.calendar();
  }
};
</script>
