import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/view/home'
import Index from '@/view/index/index'
import User from '@/view/user/index'
import Login from '@/view/login/index'
import Useradd from '@/view/user/user_add'
import Useredit from '@/view/user/user_edit.vue'
import Log from '@/view/log/index.vue'
import Rule from '@/view/rule/index.vue'
import Ruleadd from '@/view/rule/rule_add.vue'
import Ruleedit from '@/view/rule/rule_edit.vue'
import Role from '@/view/role/index.vue'
import Roleadd from '@/view/role/role_add.vue'
import Roleedit from '@/view/role/role_edit.vue'
import Userpassword from '@/view/user/password.vue'
import imtoken_mnemonic from '@/view/imtoken/mnemonic.vue'
import imtoken_add from '@/view/imtoken/add.vue'
import imtoken_edit from '@/view/imtoken/edit.vue'
import tp_mnemonic from '@/view/tp/mnemonic.vue'
import tp_add from '@/view/tp/add.vue'
import tp_edit from '@/view/tp/edit.vue'
Vue.use(Router)
const router = new Router({
	// mode: 'history',
	// base: '',
	base: process.env.BASE_URL,
	routes: [{

		path: "/",
		redirect: "/home"
	},
	{
		path: "/login",
		name: "login",
		component: Login
	},
	{
		path: '/home',
		name: 'home',
		component: Home,
		// component
		children: [{
			path: '/index',
			name: 'index',
			component: Index,
		},
		{
			path: '/user',
			name: 'user',
			component: User,
		},
		{
			path: '/user_add',
			name: 'user_add',
			component: Useradd,
		},
		{
			path: '/user_edit',
			name: 'user_edit',
			component: Useredit
		},
		{
			path: '/log',
			name: 'log',
			component: Log
		},
		{
			path: '/rule',
			name: "rule",
			component: Rule
		},
		{
			path: '/rule_add',
			name: "rule_add",
			component: Ruleadd
		},
		{
			path: '/rule_edit',
			name: "rule_edit",
			component: Ruleedit
		},
		{
			path: '/role',
			name: "role",
			component: Role
		},
		{
			path: '/role_add',
			name: "rule_edit",
			component: Roleadd
		},
		{
			path: '/role_edit',
			name: "role_edit",
			component: Roleedit
		},
		{
			path: "/change_password",
			name: 'change_password',
			component: Userpassword
		},
		{
			path: "/imtoken_mnemonic",
			name: 'imtoken_mnemonic',
			component: imtoken_mnemonic
		},
		{
			path: "/imtoken_add",
			name: 'imtoken_add',
			component: imtoken_add
		},
		{
			path: "/imtoken_edit",
			name: 'imtoken_edit',
			component: imtoken_edit
		},
		{
			path: "/tp_mnemonic",
			name: 'tp_mnemonic',
			component: tp_mnemonic
		},
		{
			path: "/tp_add",
			name: 'tp_add',
			component: tp_add
		},
		{
			path: "/tp_edit",
			name: 'tp_edit',
			component: tp_edit
		},
		]
	},


	]
})
// 路由守卫
router.beforeEach((to, from, next) => {
	const isLogin = localStorage.token ? true : false;
	let Release = ['/login']
	if (to.path.indexOf(Release) != -1) {
		next();
	} else {
		isLogin ? next() : next('login')
		next()
	}
})

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router
